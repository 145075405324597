import React, { useContext } from "react";
import { Container, Typography } from "cleanplate";
import PaymentForm from "components/forms/payment-form";
import { AuthStateContext } from "contexts/auth";
import { useNavigate } from "react-router-dom";

const PaymentPage = () => {
  const { user } = useContext(AuthStateContext);
  const navigate = useNavigate();

  const handleSave = (values, setSubmitting) => {
    console.log(values);
    setTimeout(() => {
      setSubmitting(false);
      navigate("/");
    }, 3000);
  };

  const handleBack = () => {
    navigate("/onboarding/family");
  };

  return (
    <Container>
      <Typography
        variant="h5"
        margin={["b-4", "l-auto", "r-auto"]}
        align="center"
      >
        Payment
      </Typography>
      <PaymentForm
        initialValues={{
          registrationFees: "500",
          membershipFees: "600",
        }}
        onSubmit={(values, setSubmitting) => handleSave(values, setSubmitting)}
        onBack={() => handleBack()}
      />
    </Container>
  );
};

export default PaymentPage;
