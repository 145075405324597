import React, { useContext, useEffect } from "react";
import { Button, Container, Typography, Alert } from "cleanplate";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input } from "components/form-controls";
import { Link, useNavigate } from "react-router-dom";
import PublicHeader from "components/public-header";
import { AuthDispatchContext, AuthStateContext, signIn } from "contexts/auth";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  password: Yup.string().required("Password is required"),
});

const LoginPage = () => {
  let navigate = useNavigate();
  const dispatch = useContext(AuthDispatchContext);
  const { isLoggedIn, user, error, isLoggingIn } = useContext(AuthStateContext);

  useEffect(() => {
    if (isLoggedIn) {
      // if (user?.email_verified) {
      //   navigate("/");
      // } else {
      //   navigate("/email-verify");
      // }
      navigate("/membership");
    }
  }, [isLoggedIn, user, navigate]);
  return (
    <Container
      display="flex"
      justify="center"
      align="center"
      className="auth-page"
    >
      <Container width="medium" showBorder padding="8" className="form-wrapper">
        <PublicHeader />
        {error && <Alert variant="error" message={error} margin="b-4" />}
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            signIn(dispatch, values);
          }}
        >
          {() => (
            <Form>
              <Input placeholder="Email Address" name="email" />
              <Input placeholder="Password" type="password" name="password" />
              <Typography margin={["b-8"]}>
                New member?<span>&nbsp;</span>
                <Link to="/auth/register">Register here!</Link>
              </Typography>
              <Button isFluid isLoading={isLoggingIn}>
                Login
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </Container>
  );
};

export default LoginPage;
