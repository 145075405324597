import React from "react";
import { Button, FormControls, Container, Typography } from "cleanplate";

const EmailVerificationPage = () => {
  return (
    <Container>
      <Typography variant="h4" margin="b-4">
        Email Verification
      </Typography>
      <form>
        <FormControls.Input placeholder="Email Address" />
        <FormControls.Input placeholder="Password" />
        <Button isLoading>Submit</Button>
      </form>
    </Container>
  );
};

export default EmailVerificationPage;
