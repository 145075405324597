import React from "react";
import { useField, useFormikContext } from "formik";
import { FormControls } from "cleanplate";

export const Input = ({
  label,
  placeholder,
  isRequired = false,
  isDisabled = false,
  className = "",
  isFluid = true,
  ...props
}) => {
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error;
  return (
    <FormControls.Input
      name={field.name}
      placeholder={placeholder}
      isRequired={isRequired}
      isDisabled={isDisabled}
      label={label}
      isFluid={isFluid}
      error={isError ? meta.error : ""}
      onChange={field.onChange}
      defaultValue={field.value}
      {...props}
    />
  );
};

export const Select = ({
  label,
  placeholder,
  isRequired = false,
  isDisabled = false,
  className = "",
  isFluid = true,
  options = [],
  onChange,
  isMulti = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error;
  const { setFieldValue } = useFormikContext();
  const handleChange = (val) => {
    const fieldValue = isMulti ? val.map((item) => item.value) : val.value;
    setFieldValue(field.name, fieldValue);
    if (typeof onChange === "function") {
      onChange(fieldValue);
    }
  };
  return (
    <FormControls.Select
      name={field.name}
      placeholder={placeholder}
      isRequired={isRequired}
      isDisabled={isDisabled}
      label={label}
      isFluid={isFluid}
      isMulti={isMulti}
      error={isError ? meta.error : ""}
      onChange={(val) => handleChange(val)}
      defaultValue={field.value}
      options={options}
    />
  );
};

export const Date = ({
  label,
  placeholder,
  isRequired = false,
  isDisabled = false,
  className = "",
  isFluid = true,
  options = [],
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error;
  const { setFieldValue } = useFormikContext();
  const handleChange = (fieldValue) => {
    console.log("fieldValue", fieldValue);
    setFieldValue(field.name, fieldValue);
    if (typeof onChange === "function") {
      onChange(fieldValue);
    }
  };
  return (
    <FormControls.Date
      placeholder={placeholder}
      isRequired={isRequired}
      isDisabled={isDisabled}
      label={label}
      isFluid={isFluid}
      error={isError ? meta.error : ""}
      onChange={(val) => handleChange(val)}
      defaultValue={field.value}
      options={options}
    />
  );
};

export const Checkbox = ({
  label,
  isRequired = false,
  isDisabled = false,
  className = "",
  isFluid = true,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error;
  const { setFieldValue } = useFormikContext();
  const handleChange = (fieldValue) => {
    setFieldValue(field.name, fieldValue);
    if (typeof onChange === "function") {
      onChange(fieldValue);
    }
  };
  return (
    <FormControls.Checkbox
      name={field.name}
      id={field.name}
      isRequired={isRequired}
      isDisabled={isDisabled}
      className={className}
      label={label}
      isFluid={isFluid}
      error={isError ? meta.error : ""}
      onChange={(val) => handleChange(val)}
      value={field.value}
    />
  );
};
