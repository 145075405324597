import React from "react";
import { Button, FormControls, Container, Typography } from "cleanplate";
import { Link } from "react-router-dom";
import PublicHeader from "../components/public-header";

const RegisterPage = () => {
  return (
    <Container
      display="flex"
      justify="center"
      align="center"
      className="auth-page"
    >
      <Container width="medium" showBorder padding="8" className="form-wrapper">
        <PublicHeader />

        <form>
          <FormControls.Input placeholder="Full Name" isFluid />
          <FormControls.Input placeholder="Email Address" isFluid />
          <FormControls.Input placeholder="Mobile Number" isFluid />
          {/* <FormControls.Select
            placeholder="Select Gender"
            options={genderOptions}
            isFluid
          />
          <FormControls.Input placeholder="Age" isFluid />
          <FormControls.Select
            placeholder="Select Blood Group"
            options={bloodGroupOptions}
            isFluid
          /> */}
          <FormControls.Input placeholder="Password" isFluid />
          <FormControls.Input placeholder="Confirm Password" isFluid />
          <Typography margin="b-4">
            Already registered?<span>&nbsp;</span>
            <Link to="/auth/login">Login here!</Link>
          </Typography>
          <Button isLoading isFluid>
            Register
          </Button>
        </form>
      </Container>
    </Container>
  );
};

export default RegisterPage;
