import React from "react";
import { Button, Container, Icon } from "cleanplate";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Select } from "components/form-controls";
import { genderOptions, bloodGroupOptions } from "constants/options";

const FamilyDetailsSchema = Yup.object().shape({
  gender: Yup.string().required("Gender is required"),
  bloodGroup: Yup.string().required("Blood Group is required"),
});

const FamilyDetailsForm = ({ initialValues, onSubmit, onBack }) => {
  return (
    <Container display="flex" justify="center" align="center">
      <Container width="large" padding="8">
        <Formik
          initialValues={initialValues}
          validationSchema={FamilyDetailsSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, setSubmitting);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Select
                placeholder="Select your gender"
                options={genderOptions}
                isFluid
                name="gender"
                label="Gender"
              />
              <Select
                placeholder="Select your blood group"
                options={bloodGroupOptions}
                isFluid
                name="bloodGroup"
                label="Blood Group"
              />

              <Container display="flex" justify="space-between" margin="t-8">
                <Button
                  variant="outline"
                  onClick={() => onBack()}
                  isDisabled={isSubmitting}
                >
                  <Icon color="#fff" name="arrow_back" />
                  Back
                </Button>
                <Button isLoading={isSubmitting}>
                  Save
                  <Icon color="#fff" name="arrow_forward" />
                </Button>
              </Container>
            </Form>
          )}
        </Formik>
      </Container>
    </Container>
  );
};

export default FamilyDetailsForm;
