import axios from "axios";

export const API = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_HOST,
  timeout: 60000,
});

// request interceptor for adding token
API.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers["auth-token"] = token;
  return config;
});

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { status } = error.response;
    if (status === 403 || status === 401) {
      localStorage.clear();
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

export function getJSON(url) {
  return API.get(url)
    .then((res) => res)
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });
}

export function postJSON(url, values) {
  return API.post(url, values)
    .then((res) => res)
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });
}

export function putJSON(url, values) {
  return API.put(url, values)
    .then((res) => res)
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });
}

export function patchJSON(url, values) {
  return API.patch(url, values)
    .then((res) => res)
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });
}

export function deleteJSON(url) {
  return API.delete(url)
    .then((res) => res)
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });
}

export function postFormData(url, values) {
  return API.post(url, values, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => res)
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });
}
