import React, { useContext } from "react";
import { Container, Typography } from "cleanplate";
import FamilyDetailsForm from "components/forms/family-details-form";
import { AuthStateContext } from "contexts/auth";
import { useNavigate } from "react-router-dom";

const FamilyDetailsPage = () => {
  const { user } = useContext(AuthStateContext);
  const navigate = useNavigate();

  const handleSave = (values, setSubmitting) => {
    console.log(values);
    setTimeout(() => {
      setSubmitting(false);
      navigate("/onboarding/payment");
    }, 3000);
  };

  const handleBack = () => {
    navigate("/onboarding/address");
  };

  return (
    <Container>
      <Typography
        variant="h5"
        margin={["b-4", "l-auto", "r-auto"]}
        align="center"
      >
        Family Details
      </Typography>
      <FamilyDetailsForm
        initialValues={{
          gender: "",
          bloodGroup: "",
        }}
        onSubmit={(values, setSubmitting) => handleSave(values, setSubmitting)}
        onBack={() => handleBack()}
      />
    </Container>
  );
};

export default FamilyDetailsPage;
