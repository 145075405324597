import React from "react";
import { useRouteError, Link } from "react-router-dom";
import { Container, Typography } from "cleanplate";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Container display="flex" align="center">
      <Container>
        <Typography variant="h1" margin="b-4">
          Oops!
        </Typography>
        <Typography variant="h5" margin="b-4">
          Sorry, an unexpected error has occurred.
        </Typography>
        <Typography margin="b-4">
          <i>{error.statusText || error.message}</i>
        </Typography>
        <Link to="/">Go back to Home</Link>
      </Container>
    </Container>
  );
}
