import React from "react";
import { Container, Stepper } from "cleanplate";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const OnboardingPage = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const handleStep = (step) => {
    navigate(step.key);
  };
  return (
    <Container>
      <Container width="half" margin={["l-auto", "r-auto"]}>
        <Stepper
          config={[
            {
              label: "Personal Details",
              key: "/onboarding",
              isCompleted: true,
              isActive: pathname === "/onboarding",
            },
            {
              label: "Address",
              key: "/onboarding/address",
              isCompleted: false,
              isActive: pathname === "/onboarding/address",
            },
            {
              label: "Family Details",
              key: "/onboarding/family",
              isCompleted: false,
              isActive: pathname === "/onboarding/family",
            },
            {
              label: "Payment",
              key: "/onboarding/payment",
              isCompleted: false,
              isActive: pathname === "/onboarding/payment",
            },
          ]}
          onClick={(step) => handleStep(step)}
          margin="8"
        />
      </Container>
      <Outlet />
    </Container>
  );
};

export default OnboardingPage;
