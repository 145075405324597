import React, { useReducer, createContext } from "react";
import { jwtDecode } from "jwt-decode";
import { getJSON, postJSON, patchJSON } from "utils/axios";
import useLocalStorage from "hooks/use-localstorage";

const initialState = {
  isLoggingIn: false,
  isVerifyingEmail: false,
  isCheckingValidity: false,
  isResettingPassword: false,
  isLoggedIn: false,
  user: null,
  error: null,
};

export const AuthStateContext = createContext();
export const AuthDispatchContext = createContext();

const ACTION_TYPES = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  CHECK_VALIDITY_REQUEST: "CHECK_VALIDITY_REQUEST",
  CHECK_VALIDITY_SUCCESS: "CHECK_VALIDITY_SUCCESS",
  CHECK_VALIDITY_FAILURE: "CHECK_VALIDITY_FAILURE",
  EMAIL_VERIFY_REQUEST: "EMAIL_VERIFY_REQUEST",
  EMAIL_VERIFY_SUCCESS: "EMAIL_VERIFY_SUCCESS",
  EMAIL_VERIFY_FAILURE: "EMAIL_VERIFY_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isLoggingIn: true,
        error: null,
      };
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
        isLoggingIn: false,
        error: null,
      };
    case ACTION_TYPES.LOGIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isLoggingIn: false,
        error: action.payload.error,
      };
    case ACTION_TYPES.EMAIL_VERIFY_REQUEST:
      return {
        ...state,
        isVerifyingEmail: true,
        error: null,
      };
    case ACTION_TYPES.EMAIL_VERIFY_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isVerifyingEmail: false,
        user: action.payload.user,
        error: null,
      };
    case ACTION_TYPES.EMAIL_VERIFY_FAILURE:
      return {
        ...state,
        isVerifyingEmail: false,
        error: action.payload.error,
      };
    case ACTION_TYPES.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isResettingPassword: true,
        error: null,
      };
    case ACTION_TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResettingPassword: false,
        error: null,
      };
    case ACTION_TYPES.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        isResettingPassword: false,
        error: action.payload.error,
      };
    case ACTION_TYPES.REGISTER_FAILURE:
      return {
        ...state,
        ...initialState,
        error: action.payload.error,
      };
    case ACTION_TYPES.LOGOUT_SUCCESS:
      return {
        ...state,
        ...initialState,
      };
    case ACTION_TYPES.CHECK_VALIDITY_REQUEST:
      return {
        ...state,
        isCheckingValidity: true,
        error: "",
      };
    case ACTION_TYPES.CHECK_VALIDITY_SUCCESS:
      return {
        ...state,
        isCheckingValidity: false,
      };
    case ACTION_TYPES.CHECK_VALIDITY_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        isCheckingValidity: false,
        error: action.payload.error,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const checkValidity = async (dispatch) => {
  try {
    dispatch({
      type: ACTION_TYPES.CHECK_VALIDITY_REQUEST,
    });
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = await jwtDecode(token);
      if (decoded && Date.now() <= decoded.exp * 1000) {
        return dispatch({
          type: ACTION_TYPES.CHECK_VALIDITY_SUCCESS,
        });
      } else {
        dispatch({
          type: ACTION_TYPES.CHECK_VALIDITY_FAILURE,
          payload: {
            error: "Session expired, please login again!",
          },
        });
      }
    } else {
      dispatch({
        type: ACTION_TYPES.CHECK_VALIDITY_FAILURE,
        payload: {
          error: "",
        },
      });
    }
  } catch (error) {
    return dispatch({
      type: ACTION_TYPES.CHECK_VALIDITY_FAILURE,
      payload: {
        error: `${error?.message}, please retry login!`,
      },
    });
  }
};

export const signIn = async (dispatch, userData) => {
  dispatch({
    type: ACTION_TYPES.LOGIN_REQUEST,
  });
  try {
    const response = await postJSON(
      `${process.env.REACT_APP_CORE_API_HOST}/api/v1/user/login`,
      userData
    );
    const token = response?.data?.["auth-token"];
    localStorage.setItem("token", token);
    const decodedUser = jwtDecode(token);
    localStorage.setItem("user", JSON.stringify(decodedUser));
    return dispatch({
      type: ACTION_TYPES.LOGIN_SUCCESS,
      payload: {
        user: decodedUser,
      },
    });
  } catch (error) {
    console.log("error", error);
    dispatch({
      type: ACTION_TYPES.LOGIN_FAILURE,
      payload: {
        error: error,
      },
    });
  }
};

export const emailVerify = async (dispatch, userData) => {
  dispatch({
    type: ACTION_TYPES.EMAIL_VERIFY_REQUEST,
  });
  try {
    const response = await postJSON(
      `${process.env.REACT_APP_API_HOST}/api/users/verify_email`,
      userData
    );
    const token = response?.data?.["auth_token"];
    const message = response?.data?.message;
    localStorage.setItem("token", token);
    if (message === "Email verified successfully") {
      const authToken = localStorage.getItem("token");
      const decodedUser = jwtDecode(authToken);
      const userProfile = await getJSON(
        `${process.env.REACT_APP_API_HOST}/api/users/staff/${decodedUser?.id}`
      );
      localStorage.setItem("user", JSON.stringify(userProfile?.data));
      return dispatch({
        type: ACTION_TYPES.EMAIL_VERIFY_SUCCESS,
        payload: {
          user: userProfile?.data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.EMAIL_VERIFY_FAILURE,
      payload: {
        error: error?.message,
      },
    });
  }
};

export const resetPassword = async (dispatch, userData) => {
  dispatch({
    type: ACTION_TYPES.RESET_PASSWORD_REQUEST,
  });
  try {
    const response = await patchJSON(
      `${process.env.REACT_APP_API_HOST}/api/users/reset_password`,
      userData
    );
    if (response) {
      return dispatch({
        type: ACTION_TYPES.RESET_PASSWORD_SUCCESS,
        payload: {},
      });
    }
    return dispatch({
      type: ACTION_TYPES.RESET_PASSWORD_FAILURE,
      payload: {
        error: "Password reset failed, please retry!",
      },
    });
  } catch (error) {
    dispatch({
      type: ACTION_TYPES.RESET_PASSWORD_FAILURE,
      payload: {
        error: error?.message,
      },
    });
  }
};

export const signOut = async (dispatch) => {
  localStorage.clear();
  return dispatch({
    type: "LOGOUT_SUCCESS",
  });
};

const AuthProvider = ({ children }) => {
  const [persistedUser] = useLocalStorage("user", {});
  const persistedUserState = {
    user: persistedUser,
    isLoggedIn: persistedUser?._id || "" > 0,
  };
  const [state, dispatch] = useReducer(reducer, persistedUserState);
  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={state}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export default AuthProvider;
