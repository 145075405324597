import React from "react";
import { Container, Typography } from "cleanplate";

const MembershipPage = () => {
  return (
    <Container>
      <Typography variant="h4" margin="b-4">
        My Membership
      </Typography>
    </Container>
  );
};

export default MembershipPage;
