import React, { useContext } from "react";
import { Container, Typography } from "cleanplate";
import AddressForm from "components/forms/address-form";
import { AuthStateContext } from "contexts/auth";
import { useNavigate } from "react-router-dom";

const AddressPage = () => {
  const { user } = useContext(AuthStateContext);
  const navigate = useNavigate();

  const handleSave = (values, setSubmitting) => {
    console.log(values);
    setTimeout(() => {
      setSubmitting(false);
      navigate("/onboarding/family");
    }, 3000);
  };

  const handleBack = () => {
    navigate("/onboarding");
  };

  return (
    <Container>
      <Typography
        variant="h5"
        margin={["b-4", "l-auto", "r-auto"]}
        align="center"
      >
        Address
      </Typography>
      <AddressForm
        initialValues={{
          property: {
            addressLineOne: "",
            addressLineTwo: "",
            pincode: "",
            landmark: "",
            geoLocation: "",
          },
          isSameAsProperty: true,
          permanent: {
            addressLineOne: "",
            addressLineTwo: "",
            pincode: "",
            landmark: "",
            geoLocation: "",
          },
        }}
        onSubmit={(values, setSubmitting) => handleSave(values, setSubmitting)}
        onBack={() => handleBack()}
      />
    </Container>
  );
};

export default AddressPage;
