import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import "cleanplate/dist/index.css";
import Root from "./routes/root";
import PublicRoot from "./routes/public-root";
import ErrorPage from "./routes/error";
import LoginPage from "./routes/login";
import RegisterPage from "./routes/register";
import EmailVerificationPage from "./routes/email-verification";
import MembershipPage from "./routes/membership";
import PaymentPage from "./routes/payments";
import ProfilePage from "./routes/profile";
import OnboardingPage from "./routes/onboarding/index";
import OnboardingPersonalDetailsPage from "./routes/onboarding/personal-details";
import OnboardingFamilyDetailsPage from "./routes/onboarding/family";
import OnboardingAddressPage from "./routes/onboarding/address";
import OnboardingPaymentPage from "./routes/onboarding/payment";
import { Button, Container, Typography } from "cleanplate";
import "stylesheets/globals.scss";

const domNode = document.getElementById("root");
const root = createRoot(domNode);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/membership",
        element: <MembershipPage />,
      },
      {
        path: "/payments",
        element: <PaymentPage />,
      },
      {
        path: "/profile",
        element: <ProfilePage />,
      },
      {
        path: "/email-verification",
        element: <EmailVerificationPage />,
      },
      {
        path: "/onboarding",
        element: <OnboardingPage />,
        children: [
          {
            path: "/onboarding",
            element: <OnboardingPersonalDetailsPage />,
          },
          {
            path: "/onboarding/address",
            element: <OnboardingAddressPage />,
          },
          {
            path: "/onboarding/family",
            element: <OnboardingFamilyDetailsPage />,
          },
          {
            path: "/onboarding/payment",
            element: <OnboardingPaymentPage />,
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    element: <PublicRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/auth/login",
        element: <LoginPage />,
      },
      {
        path: "/auth/register",
        element: <RegisterPage />,
      },
    ],
  },
]);

const Fallback = ({ error, resetErrorBoundary }) => {
  return (
    <Container role="alert">
      <Typography>Something went wrong!</Typography>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <Button onClick={() => resetErrorBoundary()}>Retry</Button>
    </Container>
  );
};

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={Fallback}>
      <RouterProvider router={router} />
    </ErrorBoundary>
  </React.StrictMode>
);
