import React, { useContext, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { Container } from "cleanplate";
import Header from "components/header";
import AuthProvider, {
  AuthDispatchContext,
  AuthStateContext,
  checkValidity,
} from "contexts/auth";

const ProtectedRoute = () => {
  const dispatch = useContext(AuthDispatchContext);
  const { isLoggedIn, isLoggingIn } = useContext(AuthStateContext);

  useEffect(() => {
    checkValidity(dispatch);
  }, [dispatch]);

  if (isLoggingIn) {
    return <p>Loading...</p>;
  }
  return isLoggedIn ? <Outlet /> : <Navigate to="/auth/login" />;
};

function Root() {
  return (
    <AuthProvider>
      <Container>
        <Header />
        <div id="detail">
          <ProtectedRoute />
        </div>
      </Container>
    </AuthProvider>
  );
}

export default Root;
