import React from "react";
import { Button, Container, Icon } from "cleanplate";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input, Select, Date } from "components/form-controls";
import { genderOptions, bloodGroupOptions } from "constants/options";

const PersonalDetailsSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  mobile: Yup.string().required("Mobile Number is required"),
  fullName: Yup.string().required("Fullname is required"),
  fatherName: Yup.string().required("Father/Husband name is required"),
  dateOfBirth: Yup.string().required("Date of birth is required"),
  gender: Yup.string().required("Gender is required"),
  bloodGroup: Yup.string().required("Blood Group is required"),
});

const PersonalDetailsForm = ({ initialValues, onSubmit }) => {
  return (
    <Container display="flex" justify="center" align="center">
      <Container width="large" padding="8">
        <Formik
          initialValues={initialValues}
          validationSchema={PersonalDetailsSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, setSubmitting);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Input
                label="Full Name"
                placeholder="Enter your fullname"
                name="fullName"
              />
              <Input
                label="Enter your email address"
                placeholder="username@domain.com"
                name="email"
                isDisabled
              />
              <Input
                label="Mobile Number"
                placeholder="Enter your 10 digit mobile number"
                name="mobile"
              />
              <Input
                label="Father/Husband Name"
                placeholder="Enter your father's or husband's fullname"
                name="fatherName"
              />
              <Date
                label="Date of Birth"
                placeholder="DD-MMM-YYYY"
                name="dateOfBirth"
              />
              <Select
                placeholder="Select your gender"
                options={genderOptions}
                isFluid
                name="gender"
                label="Gender"
              />
              <Select
                placeholder="Select your blood group"
                options={bloodGroupOptions}
                isFluid
                name="bloodGroup"
                label="Blood Group"
              />

              <Button margin={["l-auto", "t-8"]} isLoading={isSubmitting}>
                Save
                <Icon color="#fff" name="arrow_forward" />
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </Container>
  );
};

export default PersonalDetailsForm;
