import React, { useContext, useState } from "react";
import {
  Container,
  Dropdown,
  Typography,
  Avatar,
  Icon,
  Button,
} from "cleanplate";
import { Link, NavLink } from "react-router-dom";
// import Logo from "../assets/logo-with-text.svg";
import Logo from "../assets/logo-website.svg";
import styles from "../stylesheets/header.module.scss";
import { AuthDispatchContext, AuthStateContext, signOut } from "contexts/auth";

const MenuLink = ({ to, label, onClick, isOpen }) => {
  const handleClick = () => {
    if (isOpen && onClick) {
      onClick();
    }
  };
  return (
    <NavLink
      to={to}
      className={(navData) => (navData.isActive ? styles["active"] : "")}
      onClick={() => handleClick()}
    >
      {label}
    </NavLink>
  );
};

const TopMenu = ({ className = "", onClick, isOpen }) => {
  return (
    <Container display="flex" className={`${styles["top-menu"]} ${className}`}>
      <MenuLink isOpen={isOpen} onClick={onClick} to="/" label="Home" />
      <MenuLink
        isOpen={isOpen}
        onClick={onClick}
        to="/membership"
        label="Membership"
      />
      <MenuLink
        isOpen={isOpen}
        onClick={onClick}
        to="/payments"
        label="Payments"
      />
    </Container>
  );
};

const ProfileMenu = () => {
  const { user } = useContext(AuthStateContext);
  const dispatch = useContext(AuthDispatchContext);
  const handleLogout = () => {
    signOut(dispatch);
  };
  return (
    <Container>
      <Typography variant="h6" margin="b-1">
        {user.fullName}
      </Typography>
      <Typography margin="b-6">{user.email}</Typography>
      <Button size="small" variant="outline" onClick={() => handleLogout()}>
        Logout
      </Button>
    </Container>
  );
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleMobileMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Container
      display="flex"
      justify="space-between"
      align="center"
      padding="x-4"
      className={styles["header"]}
    >
      <Button
        className={styles["mobile-menu"]}
        onClick={() => handleMobileMenu()}
      >
        <Icon name="menu" />
      </Button>
      <Link to="/" className={styles["logo"]}>
        <img src={Logo} width={320} alt="NLRRWA" />
      </Link>
      <TopMenu
        className={
          isOpen ? styles["mobile-menu-active"] : styles["mobile-menu-inactive"]
        }
        isOpen={isOpen}
        onClick={() => setIsOpen(false)}
      />
      <Container display="flex" className={styles["avatar"]}>
        <Dropdown
          trigger={<Avatar name="Sivadass Navaneethan" />}
          content={<ProfileMenu />}
        />
      </Container>
    </Container>
  );
};

export default Header;
