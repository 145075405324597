import React from "react";
import { Button, Container, Icon } from "cleanplate";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input } from "components/form-controls";

const PaymentSchema = Yup.object().shape({
  registrationFees: Yup.string().required("Registration fee is required"),
  membershipFees: Yup.string().required("Membership fee is required"),
});

const PaymentForm = ({ initialValues, onSubmit, onBack }) => {
  return (
    <Container display="flex" justify="center" align="center">
      <Container width="large" padding="8">
        <Formik
          initialValues={initialValues}
          validationSchema={PaymentSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, setSubmitting);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Input
                label="Registration Fees"
                placeholder="500"
                name="registrationFees"
              />
              <Input
                label="Annual Membership Fees"
                placeholder="600"
                name="membershipFees"
              />

              <Container display="flex" justify="space-between" margin="t-8">
                <Button
                  variant="outline"
                  onClick={() => onBack()}
                  isDisabled={isSubmitting}
                >
                  <Icon color="#fff" name="arrow_back" />
                  Back
                </Button>
                <Button isLoading={isSubmitting}>
                  Pay Now
                  <Icon color="#fff" name="arrow_forward" />
                </Button>
              </Container>
            </Form>
          )}
        </Formik>
      </Container>
    </Container>
  );
};

export default PaymentForm;
