import React from "react";
import { Button, Container, Icon, Typography } from "cleanplate";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input, Checkbox } from "components/form-controls";

// TODO: Recheck conditional validation
const AddressSchema = Yup.object().shape({
  property: Yup.object().shape({
    addressLineOne: Yup.string().required("Address line 1 is required"),
    addressLineTwo: Yup.string().required("Address line 2 is required"),
    postalCode: Yup.string().required("Pincode is required"),
    landMark: Yup.string(),
    geoLocation: Yup.string(),
  }),
  isSameAsProperty: Yup.bool(),
  permanent: Yup.object().when("isSameAsProperty", {
    is: false,
    then: Yup.object().shape({
      addressLineOne: Yup.string().required("Address line 1 is required"),
      addressLineTwo: Yup.string().required("Address line 2 is required"),
      postalCode: Yup.string().required("Pincode is required"),
      landMark: Yup.string(),
      geoLocation: Yup.string(),
    }),
  }),
});

const PersonalDetailsForm = ({ initialValues, onSubmit, onBack }) => {
  return (
    <Container display="flex" justify="center" align="center">
      <Container width="large" padding="8">
        <Formik
          initialValues={initialValues}
          validationSchema={AddressSchema}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, setSubmitting);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <Container margin="b-4">
                <Typography margin="b-6" variant="h6">
                  Property Address
                </Typography>
                <Input
                  label="Address Line 1"
                  placeholder="Door No., Building, Street, Main Road"
                  name="property.addressLineOne"
                  isRequired
                />

                <Input
                  label="Address Line 2"
                  placeholder="Semmancheri, Chennai"
                  name="property.addressLineTwo"
                  isRequired
                />

                <Input
                  label="Pincode"
                  placeholder="600119"
                  name="property.pincode"
                  isRequired
                />

                <Input
                  label="Landmark"
                  placeholder="Behind Nila Supermarket"
                  name="property.landmark"
                />

                <Input
                  label="Geolocation"
                  placeholder="https://maps.app.goo.gl/zxbn9sbBzP1rhtS16"
                  name="property.geoLocation"
                />
              </Container>

              <Container margin="b-4">
                <Typography margin="b-3" variant="h6">
                  Permamnent Address
                </Typography>
                <Checkbox
                  name="isSameAsProperty"
                  id="isSameAsProperty"
                  label="Same as Property Address"
                />

                {!values?.isSameAsProperty && (
                  <>
                    <Input
                      label="Address Line 1"
                      placeholder="Door No., Building, Street, Main Road"
                      name="permanent.addressLineOne"
                      isRequired
                    />

                    <Input
                      label="Address Line 2"
                      placeholder="Semmancheri, Chennai"
                      name="permanent.addressLineTwo"
                      isRequired
                    />

                    <Input
                      label="Pincode"
                      placeholder="600119"
                      name="permanent.pincode"
                      isRequired
                    />

                    <Input
                      label="Landmark"
                      placeholder="Behind Nila Supermarket"
                      name="permanent.landmark"
                    />

                    <Input
                      label="Geolocation"
                      placeholder="https://maps.app.goo.gl/zxbn9sbBzP1rhtS16"
                      name="permanent.geoLocation"
                    />
                  </>
                )}
              </Container>

              <Container display="flex" justify="space-between" margin="t-8">
                <Button
                  variant="outline"
                  onClick={() => onBack()}
                  isDisabled={isSubmitting}
                >
                  <Icon color="#fff" name="arrow_back" />
                  Back
                </Button>
                <Button isLoading={isSubmitting}>
                  Save
                  <Icon color="#fff" name="arrow_forward" />
                </Button>
              </Container>
            </Form>
          )}
        </Formik>
      </Container>
    </Container>
  );
};

export default PersonalDetailsForm;
