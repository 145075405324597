import React from "react";
import { Container } from "cleanplate";
import { Link } from "react-router-dom";
// import Logo from "../assets/logo-with-text.svg";
import Logo from "../assets/logo-website.svg";

const PublicHeader = () => {
  return (
    <Container margin="b-6" display="flex" justify="center">
      <Link to="/">
        <img className="public-logo" src={Logo} width={286} alt="NRRWA" />
      </Link>
    </Container>
  );
};

export default PublicHeader;
