import React, { useContext } from "react";
import { Container, Typography } from "cleanplate";
import PersonalDetailsForm from "components/forms/personal-details-form";
import { AuthStateContext } from "contexts/auth";
import { useNavigate } from "react-router-dom";

const PersonalDetailsPage = () => {
  const { user } = useContext(AuthStateContext);
  const navigate = useNavigate();

  const handleSave = (values, setSubmitting) => {
    console.log(values);
    setTimeout(() => {
      setSubmitting(false);
      navigate("/onboarding/address");
    }, 3000);
  };

  return (
    <Container>
      <Typography
        variant="h5"
        margin={["b-4", "l-auto", "r-auto"]}
        align="center"
      >
        Personal Details
      </Typography>
      <PersonalDetailsForm
        initialValues={{
          fullName: user?.fullName,
          email: user?.email,
          mobile: user?.mobile,
          fatherName: "",
          dateOfBirth: "",
          gender: "",
          bloodGroup: "",
        }}
        onSubmit={(values, setSubmitting) => handleSave(values, setSubmitting)}
      />
    </Container>
  );
};

export default PersonalDetailsPage;
